import { Box, useTheme, Theme } from '@mui/material';
import { advanceUserWorkflow, finishSurvey, updateSurvey, useAppDispatch, useAppSelector } from 'app/store';
import { QuestionStepper } from './questions';
import { findAffectedQuestions, surveyProgress, calculateDass21Score } from './helpers';
import type { StyleForSurveyCategory } from 'app';
import { Survey, SurveyProgress } from '@vega/common';
import { QuestionPages } from './questions/QuestionPages';
import { Timestamp } from 'firebase/firestore';
import { i18next } from '@vega/common';
const t = i18next.t;
export type SurveyQuestionsProps = {
  survey: Survey;
  allUsersSurveysProgress: { [surveyKey: string]: SurveyProgress };
  hideProgress?: boolean;
};

export function SurveyQuestions(props: SurveyQuestionsProps) {
  const { survey, allUsersSurveysProgress } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user: userProfile } = useAppSelector((state) => state.profile.data!);
  const currentSurveyProgress = allUsersSurveysProgress[survey.key];

  const handleChange = async (data: Record<string, any>) => {
    const [_total, _completed] = surveyProgress(
      survey,
      { ...currentSurveyProgress, ...data },
      allUsersSurveysProgress,
      userProfile,
    );
    const sectionName = survey.sections[currentSurveyProgress._step]?.key;
    // We can't just use serverTimestamp for these timestamps, because the data is also dispatched to the redux store :(
    const nowTimestamp = Timestamp.fromDate(new Date());
    const timestamps = !sectionName
      ? undefined
      : {
          [`_modified_first_${sectionName}`]: currentSurveyProgress[`_first_modified_${sectionName}`] ?? nowTimestamp,
          [`_modified_last_${sectionName}`]: nowTimestamp,
        };
    const affectedQuestionKeys = findAffectedQuestions(data, allUsersSurveysProgress, survey, userProfile);

    const additionalDataToChange = affectedQuestionKeys.reduce((acc, questionKey) => {
      acc[questionKey] = null;
      return acc;
    }, {} as Record<string, any>);
    const _dass21Score = calculateDass21Score(data, allUsersSurveysProgress, survey);
    await dispatch(
      updateSurvey({
        id: survey.key,
        data: { ...data, ...additionalDataToChange, _total, _completed, _dass21Score, ...timestamps },
      }),
    );
  };

  const setStep = async (step: number) => {
    if (step === survey.sections.length) {
      await dispatch(finishSurvey());
      await dispatch(advanceUserWorkflow(survey.key));
    }
    await dispatch(updateSurvey({ id: survey.key, data: { _step: step } }));
  };

  return (
    <Box sx={containerStyleForSurvey(theme)[survey.category]}>
      {survey.category === 'rct' ? (
        <QuestionStepper
          survey={survey}
          allUsersSurveysProgress={allUsersSurveysProgress}
          setStep={setStep}
          onChange={handleChange}
          finishLabel={t('general.submit')}
        />
      ) : (
        <QuestionPages
          hideProgress={props.hideProgress}
          survey={survey}
          onChange={handleChange}
          usersSurveyProgress={allUsersSurveysProgress}
          setStep={setStep}
          finishLabel={t('general.submit')}
        />
      )}
    </Box>
  );
}

const containerStyleForSurvey = (theme: Theme): StyleForSurveyCategory => ({
  gmci: { backgroundColor: theme.palette.checkInSurvey.main, position: 'relative' },
  rct: { p: '2rem' },
});
