import { useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { SitePage } from 'app/layout';
import { useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { SurveyQuestions } from 'app/survey/SurveyQuestions';
import { SurveyFeedback } from 'app/survey/SurveyFeedback';
import { Helmet } from 'react-helmet';

import { surveys, UserDetails, RctSurveyKeys, SurveyProgress } from '@vega/common';

type SurveyTemplateProps = {
  userSurveysProgress: Record<string, SurveyProgress>;
  user: UserDetails;
  surveyKey: RctSurveyKeys;
};

export function SurveyTemplate(props: SurveyTemplateProps) {
  const { user, userSurveysProgress, surveyKey } = props;
  const usersSurveyProgress = userSurveysProgress[surveyKey];
  const activeStep = usersSurveyProgress._step || 0;
  const survey = useMemo(() => (
    surveys.find(({ key }) => (key === surveyKey))!
  ), [surveyKey]);
  const inProgress = activeStep < survey.sections.length;

  useEffect(() => {
    if (!inProgress && survey.category === 'rct') {
      navigate('/dashboard');
    }
  }, [inProgress, survey.category]);
  return (
    <SitePage>
      {inProgress ? (
        <SurveyQuestions survey={survey} allUsersSurveysProgress={userSurveysProgress} />
      ) : survey.category === 'rct' ? null : (
        <SurveyFeedback user={user} survey={survey} />
      )}
    </SitePage>
  );
}

type PageProps = {
  pageContext: {
    surveyKey: RctSurveyKeys,
  };
};

export default ensureAuth((props: PageProps) => {

  const surveyKey = props.pageContext.surveyKey;
  const survey = surveys.find(({ key }) => (key === surveyKey))!;
  const { user, surveyProgress: userSurveysProgress } = useAppSelector((state) => state.profile.data!);

  return (
    <>
      <Helmet>
        <title>{survey.title}</title>
        <link rel="stylesheet" type="text/css" media="print" href="/stylesheets/print-survey-feedback.css"></link>
      </Helmet>
      {
        (!userSurveysProgress[surveyKey]) ? (
          <div>The survey is locked</div>
        ) : (
          <SurveyTemplate user={user} surveyKey={surveyKey} userSurveysProgress={userSurveysProgress} />
        )
      }
    </>
  );
});
