import { Article } from './Article';

import { RctSurveyKeys, UserDetails } from '@vega/common';
import { Feedback as FeedbackComponent } from '@vega/common-react';
import { startSurvey, useAppDispatch, useAppSelector } from 'app/store';

export type FeedbackProps = {
  surveyKey: RctSurveyKeys;
  user: UserDetails;
  logRecommendationAction: (id: string, navigate: boolean) => Promise<void>;
  saveUserFeedback: (feedback: string) => Promise<void>;
};

export function Feedback(props: FeedbackProps) {
  const appState = useAppSelector((state) => state.profile.data);
  const dispatch = useAppDispatch();
  if (!appState) {
    return null;
  }
  return (
    <Article sx={{ '@media print': { size: 'portrait' } }}>
      <FeedbackComponent
        surveyKey={props.surveyKey}
        allSurveyResponses={appState.surveyProgress}
        userDetails={appState.user}
        logRecommendationAction={props.logRecommendationAction}
        saveUserFeedback={props.saveUserFeedback}
      />
    </Article>
  );
}
